import React, {Component} from 'react'
import Link from 'gatsby-link'
import staticdata from '../../staticdata.json'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import { Controller, Scene } from 'react-scrollmagic'

import Layout from "../components/layout"

import Circle from '../components/circle.js'
import HeadPage from '../components/headPage'
import CardProject from '../components/cardProject'
import TileProject from '../components/tileProject'
import Cell from '../components/cell'
import HeroImage from '../components/heroImage'
import BackgroundText from '../components/backgroundText.js'

import sevenhugsHeroReveal from '../images/sevenhugs-thumb-gif3.gif'
import CustomUI from '../images/Custom-UI.png'
import triberMotion from '../images/triberMotion.gif'
import agorizeThumbnailProduct from '../images/ChallengeBuilder-video.gif'
import agorizeThumbnailSystem from '../images/agorize-thumbnail.png'
import doctolibThumbnail from '../images/doctolib-thumbnail.gif'
import doctolibThumbnail2 from '../images/doctolib-thumbnail-2.png'
import livestormThumbnail from '../images/livestorm-thumbnail1.gif'
import livestormThumbnail1 from '../images/livestorm-thumbnail1.png'
import livestormThumbnail2 from '../images/livestorm-thumbnail2.png'
import seCollab3 from '../images/SE-Collab-3.png'
import banner01 from '../images/banner01.png'
import granularThumbnail from '../images/granular-thumbnail.png';


class Work extends Component {

	constructor(props) {
		super(props);
	}

	render = () => {
	return (
	<Layout>
		<div> 

		<div className="CardsList">
		<Fade bottom cascade delay={550} duration={550} distance="1.6em" ssrFadeout>
			
			<Link to="/sevenhugs-product">
				<CardProject 
					caption="Sevenhugs (Acquired by Qorvo Inc) — 2018-2020"
					title="Allowing users to customize their Smart Remote interface"
					role="Product Design · Design Systems"
					image={CustomUI}
					fakelink="Read case study"
					

				/>
			</Link>
			<Link to="/sevenhugs-brand">
				<CardProject 
					caption="Sevenhugs (Acquired by Qorvo Inc) — 2018-2020"
					title="Launching Smart Remote X and Smart Remote U"
					role="Brand Design · Packaging · UX/UI · Motion · Product Video"
					image={sevenhugsHeroReveal}
					fakelink="Read case study"
					

				/>
			</Link>
			<Link to="/agorize-product">
				<CardProject
					caption="Agorize — 2017"
					title="Designing the Challenge Builder feature"
					role="Product Design"
					image={agorizeThumbnailProduct}
					fakelink="Read case study"
					
				/>
			</Link>
			<Link to="/agorize-design-system">
				<CardProject
					caption="Agorize — 2017"
					title="Initiating and leading Agorize Design System"
					role="Design Systems"
					image={agorizeThumbnailSystem}
					fakelink="Read case study"
					
				/>
			</Link>
			<Link to="/triber">
				<CardProject 
					caption="Triber (Acquired by NRJ Group) — 2015"
					title="Building apps for influencers. From Beta program to acquisition."
					role="Product Design · Brand Design"
					image={triberMotion}
					fakelink="Read case study"
					
				/>
			</Link>
		</Fade>
		</div>
		

		
		
		<div className="Footer">
			<div className="Left">
				<p>
					Interested in working together?<br />
					<a href="https://www.linkedin.com/in/alexandretrimoulet/" target="_blank">Get in touch →</a>
				</p>
			</div>
			<div className="Right">
				<p className="SocialLinks">
					Find me on
					<a id="linkedin" href="https://www.linkedin.com/in/alexandretrimoulet" target="_blank"> LinkedIn</a>, 
					<a id="medium" href="https://medium.com/@AlexTrimoulet" target="_blank"> Medium</a>, 
					<a id="twitter" href="https://twitter.com/AlexTrimoulet" target="_blank"> Twitter</a>,
					<a id="soundcloud" href="https://soundcloud.com/osphoz/" target="_blank"> SoundCloud</a>,
					<a id="dribbble" href="https://dribbble.com/alexandret" target="_blank"> Dribbble</a>, 
					<a id="producthunt" href="https://www.producthunt.com/@alextrimoulet" target="_blank"> Product Hunt</a>,  & 
					<a id="instagram" href="https://instagram.com/alextrmlt" target="_blank"> Instagram</a>.
				</p>
			</div>
			<div className="developedBy">
				Portfolio designed & developed in React by Alex Trimoulet 💪🏻 <br />
				© All rights reserved 2022
			</div>
		</div>


	</div>
	</Layout>
)
}
}

export default Work

